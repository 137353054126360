
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
interface ITeacher {
  _id: string;
  employee: {
    firstName: string;
    lastName: string;
    photo: string;
    birthDate: string;
  };
}

export default defineComponent({
  name: "profile-teacher",
  components: {},

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const apiUrl = store.getters.serverConfigUrl.base_url + "/";

    const route = useRoute();
    const TeacherID = route.params.id;
    const loading = ref(true);
    const teacher = ref<ITeacher>({
      _id: "",
      employee: {
        firstName: "",
        lastName: "",
        photo: "",
        birthDate: "",
      },
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(t("teacher.teacherProfile"), []);

      await ApiService.post("/teachers/filter", {
        query: { _id: TeacherID },
      })
        .then(({ data }) => {
          if (data.length > 0) teacher.value = data[0];
          else console.error(data);
        })
        .catch((e) => console.log(e));
    });

    return {
      t,
      TeacherID,
      teacher,
      apiUrl,
      loading,
    };
  },
});
